import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';
import { FlagWrapper, Flex, FlagToChoose, SelectIconWrapper } from './LanguageSelector.style';

const LanguageSelector = (): JSX.Element => {
  const { i18n } = useTranslation();
  const [openLanguageSelector, setOpenLanguageSelector] = useState<boolean>(false);
  const isEnglishChosen = i18n.language === 'en';
  localStorage.setItem('wlabadi_lang', i18n.language);

  const selectLanguage = () => {
    i18n.changeLanguage(isEnglishChosen ? 'pl' : 'en');
    setOpenLanguageSelector(false);
  };

  return (
    <Flex>
      <FlagWrapper onClick={() => setOpenLanguageSelector(!openLanguageSelector)}>
        <Icon icon={!isEnglishChosen ? 'polandFlag' : 'UK'} width='2rem' height='2rem' />
        <SelectIconWrapper>
          <Icon icon='select' fill='black' width='0.75rem' height='0.75rem' />
        </SelectIconWrapper>
      </FlagWrapper>
      {openLanguageSelector && (
        <FlagToChoose onClick={selectLanguage}>
          <Icon icon={isEnglishChosen ? 'polandFlag' : 'UK'} width='2rem' height='2rem' />
        </FlagToChoose>
      )}
    </Flex>
  );
};

export default LanguageSelector;
