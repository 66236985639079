import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'hooks';
import { Spinner } from 'components';
import { Main, About, Gallery, Pricing, Contact, DesktopView } from 'views';
import { Navbar } from 'components';

function App() {
  const { width } = useWindowDimensions();
  const isMobile = width < 500;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const renderContent = () =>
    isMobile ? (
      <>
        <Navbar />
        <Main />
        <About />
        <Gallery />
        <Pricing />
        <Contact />
      </>
    ) : (
      <DesktopView />
    );

  return loading ? <Spinner /> : renderContent();
}

export default App;
