import { TFunction } from 'i18next';
import sizes from 'assets/sizes';

export const getSections = (t: TFunction) => [
  {
    type: t('ALL_BREEDS'),
    dotText: t('All Breeds'),
    id: 0,
    priceHotel: 25,
    priceWalks: 11,
    breeds: [],
    src: sizes.Puppy,
  },
  // {
  //   type: t('MINI_DOGS'),
  //   dotText: t('MINI'),
  //   id: 1,
  //   priceHotel: 25,
  //   priceWalks: 11,
  //   breeds: ['Yorkshire terrier', 'Pomeranian', 'Chihuahua'],
  //   src: sizes.Mini,
  // },
  // {
  //   type: t('SMALL_DOGS'),
  //   dotText: t('SMALL'),
  //   id: 2,
  //   priceHotel: 25,
  //   priceWalks: 11,
  //   breeds: ['Shih tzu', 'Maltese', 'Springer spaniel'],
  //   src: sizes.Small,
  // },
  // {
  //   type: t('MEDIUM_DOGS'),
  //   dotText: t('MEDIUM'),
  //   id: 3,
  //   priceHotel: 25,
  //   priceWalks: 11,
  //   breeds: ['Labrador Retriever', 'Border Collie', 'Golden Retriever'],
  //   src: sizes.Medium,
  // },
  // {
  //   type: t('BIG_DOGS'),
  //   dotText: t('BIG'),
  //   id: 4,
  //   priceHotel: 25,
  //   priceWalks: 11,
  //   breeds: ['Pitbull Terrier', 'Alaskan Malamute', 'Boxer'],
  //   src: sizes.Big,
  // },
];

export const getAdditionalServices = (t: TFunction) => [
  { info: `${t('BATHING')} - ${t('SMALL_AND_MEDIUM_DOGS')}`, price: 10 },
  { info: `${t('BATHING')} - ${t('BIG_DOGS')}`, price: 15 },
  { info: t('MEDICATIONS_AT_SCHEDULED_TIMES'), price: 10 },
];

export const getAdditionalServicesForWalks = (t: TFunction) => [
  { info: t('INDIVIDUAL_WALKS'), price: 2 },
  { info: t('EXTRA_WITH_TRANING'), price: 2 },
];
