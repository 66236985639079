import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleSection } from 'components';
import dog from 'assets/images/dog-pricing.png';
import { getAdditionalServices, getSections, getAdditionalServicesForWalks } from './data';
import {
  Wrapper,
  Services,
  Service,
  Info,
  Price,
  Photo,
  Details,
  Title,
  Item,
  Text,
  Breed,
  AdditionalText,
  PricingDetails,
} from './Pricing.style';

const Pricing = () => {
  const { t } = useTranslation();
  const SECTIONS = getSections(t);
  const [walks, setIsWalks] = useState<boolean>(true);
  const ADDITIONAL_SERVICES = walks ? getAdditionalServices(t) : getAdditionalServicesForWalks(t);

  return (
    <Wrapper id='pricing'>
      <TitleSection
        imgSrc={dog}
        title='PRICING'
        withButtons
        setIsWalks={setIsWalks}
        walks={walks}
      />

      <PricingDetails>
        {SECTIONS.map(({ type, breeds, src, priceHotel, priceWalks }) => {
          const price = walks ? `${priceHotel}£ / ${t('DAY')}` : `${priceWalks}£ / ${t('HOUR')}`;
          return (
            <Item key={type}>
              <Photo src={src} />
              <Details>
                <Title>{type}</Title>
                <Text>
                  {t('PRICE')}: {price}
                </Text>
                {breeds.length ? (
                  <>
                    <Text>{t('BREEDS_EXAMPLE')}:</Text>
                    {breeds.map((breed) => (
                      <Breed key={breed}>{breed}</Breed>
                    ))}
                  </>
                ) : (
                  ''
                )}
              </Details>
            </Item>
          );
        })}
      </PricingDetails>

      <Services>
        <AdditionalText>{t('ADDITIONAL_SERVICES')}</AdditionalText>
        {ADDITIONAL_SERVICES.map(({ info, price }) => (
          <Service key={info}>
            <Info>{info}</Info>
            <Price>{price}£</Price>
          </Service>
        ))}
      </Services>
    </Wrapper>
  );
};

export default Pricing;
