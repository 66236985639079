import { useState } from 'react';
import dog from 'assets/images/dog-gallery.png';
import { TitleSection } from 'components';
import {
  GalleryWrapper,
  Photo,
  PhotoIcon,
  PhotoIconWrapper,
  PhotoList,
  Wrapper,
} from './Gallery.style';
import PHOTOS from 'assets/photos';

const Gallery = () => {
  const [photoNr, setPhotoNr] = useState(0);
  const changePhoto = (selectedPhoto: number) => {
    if (PHOTOS.length !== selectedPhoto) return setPhotoNr(selectedPhoto);
    setPhotoNr(0);
  };

  return (
    <Wrapper id='gallery'>
      <TitleSection imgSrc={dog} title='GALLERY' />
      <GalleryWrapper>
        <Photo src={PHOTOS[photoNr]} onClick={() => changePhoto(photoNr + 1)} alt='Dog' />
        <PhotoList>
          {PHOTOS.map((photo, i) => (
            <PhotoIconWrapper key={i}>
              <PhotoIcon src={photo} onClick={() => changePhoto(i)} alt='Dog' />
            </PhotoIconWrapper>
          ))}
        </PhotoList>
      </GalleryWrapper>
    </Wrapper>
  );
};

export default Gallery;
