import styled from 'styled-components';

type tWrapper = {
  active?: boolean;
  href?: string;
};

type tActive = {
  active?: boolean;
};

export const ContactInfoWrapper = styled.a<tWrapper>`
  height: 32px;
  background: ${({ active }) => (active ? 'var(--theme-light-pink)' : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: ${({ active }) => (active ? ' 1.25rem' : ' 1.25rem 3.25rem')};
`;

export const ContactTileTitle = styled.p<tActive>`
  margin: 0;
  padding: 0;
  color: ${({ active }) => (active ? 'var(--theme-main-color)' : 'var(--theme-black)')};
  line-height: 1.5rem;
  font-size: 1rem;

  @media (min-width: 500px) {
    font-size: 0.875rem;
    line-height: 1.3rem;
  }
`;

export const ContactTileSubtitle = styled(ContactTileTitle)<tActive>`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ active }) => (active ? 'var(--theme-main-color)' : 'var(--theme-black)')};
`;

export const TextWrapper = styled.div<tActive>`
  padding-left: 1.5rem;
`;
