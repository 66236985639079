import { Map, Overlay } from 'pigeon-maps';
import { IconWithInfoTile } from 'components';
import { Wrapper, Circle } from './Contact.style';

const Contact = () => {
  return (
    <Wrapper id='contact'>
      <Map height={350} defaultCenter={[52.7721, -1.2062]} defaultZoom={10}>
        <Overlay anchor={[52.7721, -1.2062]} offset={[110, 100]}>
          <Circle />
        </Overlay>
      </Map>
      <IconWithInfoTile
        icon='location'
        title='Loughborough'
        subtitle='ADDRESS'
        active
        href='https://www.google.com/maps/place/Loughborough,+UK/@52.7646718,-1.2562979,13z/data=!3m1!4b1!4m5!3m4!1s0x4879d926a0f7320d:0x8bd926f2baff035d!8m2!3d52.772099!4d-1.206166'
      />
      <IconWithInfoTile
        icon='email'
        title='wlabadi.doggycare@gmail.com'
        subtitle='EMAIL'
        href='mailto:wlabadi.doggycare@gmail.com?subject=Email ze strony'
      />
    </Wrapper>
  );
};

export default Contact;
