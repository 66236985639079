import { useTranslation } from 'react-i18next';
import { Icon, IconWithInfoTile, LanguageSelector } from 'components';
import { Wrapper, LanguageWrapper, InfoWrapper, Flex, Paragraph } from './DesktopView.style';

const DesktopView = () => {
  const { t } = useTranslation();

  const PDF_FILE = `https://wlabadi-pdf-files.s3.eu-west-2.amazonaws.com/document/pet-care-agreement.docx`;

  return (
    <Wrapper>
      <LanguageWrapper>
        <LanguageSelector />
      </LanguageWrapper>
      <Icon icon='logo' width='20rem' height='20rem' />
      <Paragraph>{t('FULL_APPLICATION_AVAILABLE_ONLY_ON_MOBILE')}</Paragraph>
      <Paragraph>{t('CONTACT_DETAILS')}</Paragraph>

      <Flex>
        <div>
          <InfoWrapper>
            <IconWithInfoTile
              title='wlabadi.doggycare@gmail.com'
              subtitle='EMAIL'
              icon='email'
              href={`mailto:wlabadi.doggycare@gmail.com?subject=Email ze wlabadi.com`}
              fill='#4367CC'
            />
          </InfoWrapper>
          <InfoWrapper>
            <IconWithInfoTile
              title='WlaBadi DogsCare'
              subtitle='Facebook'
              icon='facebook'
              href='https://www.facebook.com/WlaBadi-DogsCare-100461505397105'
              fill='#3B5998'
            />
          </InfoWrapper>
        </div>
        <div>
          <InfoWrapper>
            <IconWithInfoTile
              title='Loughborough'
              subtitle='ADDRESS'
              icon='location'
              href='https://www.google.com/maps/place/Loughborough,+UK/@52.7646718,-1.2562979,13z/data=!3m1!4b1!4m5!3m4!1s0x4879d926a0f7320d:0x8bd926f2baff035d!8m2!3d52.772099!4d-1.206166'
              fill='#D58296'
            />
          </InfoWrapper>
          <InfoWrapper>
            <IconWithInfoTile
              title='wlabadi_dogscare'
              subtitle='Instagram'
              icon='instagramColor'
              href='https://www.instagram.com/wlabadi_dogscare/'
            />
          </InfoWrapper>
        </div>
      </Flex>
      <InfoWrapper>
        <IconWithInfoTile
          icon='pdf'
          title='APPLICATION_FORM'
          subtitle='CLICK_TO_DOWNLOAD'
          href={PDF_FILE}
          download
        />
      </InfoWrapper>
    </Wrapper>
  );
};

export default DesktopView;
