import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
  margin-bottom: 2rem;
`;

export const TextWrapper = styled.div`
  z-index: 1000;
  border-radius: 10%;
  margin-top: -1rem;
`;

export const Text = styled.p`
  padding: 0rem 2rem 2rem 2rem;
  margin: 0;
  font-family: 'OpenSans';
  text-align: center;
  line-height: 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--theme-black);
`;

export const SubtitleText = styled.p`
  padding: 0rem 2rem 0.5rem 2rem;
  margin: 0;
  font-family: 'OpenSans';
  font-weight: 500;
  line-height: 25px;
  font-size: 1.15rem;
`;

export const Video = styled.video`
  width: calc(100% - 4rem);
  padding: 0 2rem 2rem 2rem;
`;
