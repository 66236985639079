import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 99;
  position: relative;
  min-height: 18vh;
  height: 18vh;
`;

export const DogImg = styled.img`
  width: auto;
  height: auto;
  align-self: flex-end;
  max-height: 130px;
`;
