import styled from 'styled-components';
import mainDog from 'assets/images/main-dog.png';
import { ViewTitle } from 'styles/GlobalStyledComponents.style';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${mainDog});
  background-size: cover;
  padding-top: 1rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 54px;
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  min-height: 54px;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
`;

export const TitleWrapper = styled.div`
  margin: 3rem auto;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Title = styled(ViewTitle)`
  margin: 0;
  padding: 1rem;
  font-size: 2.5rem;
  max-width: 75%;
  text-align: center;
`;
