import { useTranslation } from 'react-i18next';
import { Icon, LanguageSelector } from 'components';
import { Wrapper, Paragraph, ContentWrapper, Title, TitleWrapper } from './Main.style';

const ICON_STYLES = {
  UP_LINE: {
    position: 'absolute',
    top: '0',
    left: '2rem',
  } as React.CSSProperties,
  BOTTOM_LINE: {
    position: 'absolute',
    bottom: '0',
    right: '2rem',
  } as React.CSSProperties,
};

const Main = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id='main'>
      <ContentWrapper>
        <Icon icon='logo' height='4rem' width='4rem' />
        <Paragraph>WlaBadi DogsCare</Paragraph>
        <LanguageSelector />
      </ContentWrapper>
      <TitleWrapper>
        <Icon icon='upLine' style={ICON_STYLES.UP_LINE} />
        <Title>{t('THE_SECOND_HOME_FOR_YOUR_DOG')}</Title>
        <Icon icon='bottomLine' style={ICON_STYLES.BOTTOM_LINE} />
      </TitleWrapper>
    </Wrapper>
  );
};

export default Main;
