import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';
import { useWindowDimensions } from 'hooks';
import { mainColor, mainGrey } from 'styles/colors';
import {
  Nav,
  NavDesktop,
  NavLinks,
  NavLinksDesktop,
  NavBarName,
  IconWrapper,
  MainWrapper,
  MenuImg,
} from './Navbar.style';

const Navbar = (): JSX.Element => {
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState<string>('main');
  const { width } = useWindowDimensions();
  const isMobile = width < 1024;

  const navBarElements = [
    {
      name: t('ABOUT'),
      linkTo: 'about',
      icon: 'petsPaw',
    },
    {
      name: t('GALLERY'),
      linkTo: 'gallery',
      icon: 'photos',
    },
    {
      name: '',
      linkTo: activeLink === 'main' ? 'about' : 'main',
      icon: activeLink === 'main' ? 'arrow' : 'home',
      hasBg: true,
    },
    {
      name: t('PRICING'),
      linkTo: 'pricing',
      icon: 'money',
    },
    {
      name: t('CONTACT'),
      linkTo: 'contact',
      icon: 'phone',
    },
  ];

  return isMobile ? (
    <>
      <MenuImg />
      <Nav>
        {navBarElements.map(({ name, linkTo, icon, hasBg }) => {
          return (
            <NavLinks
              id='navLink'
              key={icon}
              to={linkTo}
              spy
              smooth
              active={activeLink === linkTo}
              onSetActive={(to) => setActiveLink(to)}
            >
              <IconWrapper>
                {hasBg ? (
                  <MainWrapper>
                    <Icon icon={icon} width='1.5rem' />
                  </MainWrapper>
                ) : (
                  <Icon
                    icon={icon}
                    width='4rem'
                    fill={activeLink === linkTo ? mainColor : mainGrey}
                  />
                )}
              </IconWrapper>
              <NavBarName>{name.toUpperCase()}</NavBarName>
            </NavLinks>
          );
        })}
      </Nav>
    </>
  ) : (
    <NavDesktop>
      {navBarElements.map((element) => {
        const { name, linkTo } = element;
        return (
          <NavLinksDesktop key={name} to={linkTo} spy smooth>
            {name}
          </NavLinksDesktop>
        );
      })}
    </NavDesktop>
  );
};

export default Navbar;
