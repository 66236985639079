import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  color: black;
`;

export const LanguageWrapper = styled.div`
  position: absolute;
  right: 3rem;
  top: 2rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
  align-items: center;
`;

export const Flex = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const Paragraph = styled.div`
  font-size: 1.1rem;
`;
