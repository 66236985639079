import styled from 'styled-components';

type tButtonSection = {
  active: boolean;
};

export const Shadow = styled.div`
  width: 100%;
  height: 50px;
  box-shadow: 0rem -1.5rem 1rem rgb(183 191 214 / 50%);
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
`;

export const UnderShadow = styled.div<tButtonSection>`
  z-index: 1;
  width: 100%;
  height: 50px;
  box-shadow: ${({ active }) =>
    active
      ? '1rem -1.5rem 1rem rgb(183 191 214 / 50%)'
      : '-0.6rem -1.5rem 1rem rgb(183 191 214 / 50%)'};
`;

const ButtonSection = styled.button<tButtonSection>`
  transition: 0.5s;
  padding-bottom: 1rem;
  font-weight: bold;
  padding-top: 0.5rem;
  height: 2.5rem;
  background: none;
  border: none;
  background-color: 'var(--theme-white)';
  outline: 0;
  color: ${({ active }) => (active ? 'var(--theme-main-color)' : '#8890a6ba')};
  font-family: Montserrat;
  font-size: 1.5rem;
  box-shadow: ${({ active }) => (active ? '0rem -1.5rem 1rem rgb(183 191 214 / 50%)' : '')};
`;

export const WalkSectionTile = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const WalkSection = styled.div`
  display: flex;
  width: 100%;
`;

export const HotelButton = styled(ButtonSection)`
  z-index: 1;
  border-top-right-radius: 15px;
`;

export const WalksButton = styled(ButtonSection)`
  z-index: 1;
  border-top-left-radius: 20px;
`;
