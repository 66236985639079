import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 90vh;
  width: 100vw;
`;

export const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(80vh - 11rem);
  margin-top: -1rem;
`;

export const MainPhoto = styled.div`
  background-color: blue;
  width: 250px;
  max-height: 250px;
  height: 250px;
  margin: 20px 0;
`;

export const Photo = styled.img`
  object-fit: contain;
  margin: 1em;
  width: 90vw;
  height: 40vh;
`;

export const PhotoList = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 90%;
`;

export const PhotoIconWrapper = styled.li`
  list-style: none;
  margin-right: 10px;
`;

export const PhotoIcon = styled.img`
  width: 25vw;
  height: 10vh;
  object-fit: cover;
`;
