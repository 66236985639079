import {
  Shadow,
  HotelButton,
  WalksButton,
  UnderShadow,
  WalkSectionTile,
  WalkSection,
} from './PricingTabs.styled';

import { useTranslation } from 'react-i18next';

type tPricingTabs = {
  withButtons?: boolean;
  setIsWalks?: Function;
  walks?: boolean;
};

const PricingTabs = ({
  withButtons = false,
  setIsWalks = () => {},
  walks = false,
}: tPricingTabs): JSX.Element => {
  const { t } = useTranslation();

  return withButtons ? (
    <WalkSection>
      <WalkSectionTile>
        <HotelButton active={walks} onClick={() => setIsWalks(true)}>
          Hotel
        </HotelButton>
        {!walks && <UnderShadow active={walks} />}
      </WalkSectionTile>
      <WalkSectionTile>
        <WalksButton active={!walks} onClick={() => setIsWalks(false)}>
          {t('WALKS')}
        </WalksButton>
        {walks && <UnderShadow active={walks} />}
      </WalkSectionTile>
    </WalkSection>
  ) : (
    <Shadow />
  );
};

export default PricingTabs;
