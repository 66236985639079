import Puppy from './puppy.png';
import Mini from './mini.png';
import Small from './small.png';
import Medium from './medium.png';
import Big from './big.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Puppy,
  Mini,
  Small,
  Medium,
  Big,
};
