import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en.json';
import plTranslation from './pl.json';

const resources: Resource = {
  en: { translation: { ...enTranslation } },
  pl: { translation: { ...plTranslation } },
};
const DEFAULT_LANGUAGE = 'en';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  i18n.use(initReactI18next).init({
    resources,
    fallbackLng: ['en', 'pl'],
    lng:  localStorage.getItem('wlabadi_lang') || DEFAULT_LANGUAGE,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
  return i18n;
};

