import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { mainColor, mainGrey } from 'styles/colors';
import { Icon } from 'components';
import {
  ContactInfoWrapper,
  ContactTileTitle,
  ContactTileSubtitle,
  TextWrapper,
} from './IconWithInfoTile.style';

type tIconWithInfoTile = {
  title: string;
  subtitle: string;
  icon: string;
  active?: boolean;
  href?: string;
  fill?: string;
  download?: boolean;
  width?: string;
  height?: string;
};

export type tRenderContent = tIconWithInfoTile & {
  t: TFunction;
};

const renderContent = (props: tRenderContent) => {
  const { active, subtitle, icon, title, href, t, fill, download, width, height } = props;
  return (
    <ContactInfoWrapper active={active} href={href} download={download}>
      <Icon icon={icon} fill={active ? mainColor : fill} width={width} height={height} />
      <TextWrapper>
        <ContactTileTitle active={active}>{t(title)}</ContactTileTitle>
        <ContactTileSubtitle active={active}>{t(subtitle)}</ContactTileSubtitle>
      </TextWrapper>
    </ContactInfoWrapper>
  );
};

const IconWithInfoTile = ({
  title,
  subtitle,
  icon,
  active = false,
  href = '',
  fill = mainGrey,
  download = false,
  width,
  height,
}: tIconWithInfoTile): JSX.Element => {
  const { t } = useTranslation();
  const props = { active, subtitle, icon, title, href, t, fill, download, width, height };

  return active ? (
    <div style={{ margin: '1rem 2rem' }}>{renderContent(props)}</div>
  ) : (
    renderContent(props)
  );
};

export default IconWithInfoTile;
