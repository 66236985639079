import photo1 from './photo1.jpeg';
import photo2 from './photo2.jpeg';
import photo3 from './photo3.jpeg';
import photo4 from './photo4.jpeg';
import photo5 from './photo5.jpeg';
import photo6 from './photo6.jpeg';
import photo7 from './photo7.jpeg';
import photo8 from './photo8.jpeg';
import photo9 from './photo9.jpeg';
import photo10 from './photo10.jpeg';
import photo11 from './photo11.jpeg';
import photo12 from './photo12.jpeg';
import photo13 from './photo13.jpeg';
import photo14 from './photo14.jpeg';
import photo15 from './photo15.jpeg';
import photo16 from './photo16.jpeg';
import photo17 from './photo17.jpeg';
import photo18 from './photo18.jpeg';

export default [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
  photo15,
  photo16,
  photo17,
  photo18
]
