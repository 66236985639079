import { useTranslation } from 'react-i18next';
import dog from 'assets/images/dog-about.png';
import { facebook } from 'styles/colors';
import { MOVIE_FILE, FACEBOOK_LINK, INSTAGRAM_LINK } from 'utils/constants';
import { TitleSection, IconWithInfoTile } from 'components';
import { Wrapper, TextWrapper, Text, SubtitleText, Video } from './About.style';

const About = () => {
  const { t } = useTranslation();

  const PDF_FILE = `https://wlabadi-pdf-files.s3.eu-west-2.amazonaws.com/document/pet-care-agreement.docx`;

  return (
    <Wrapper id='about'>
      <TitleSection imgSrc={dog} title='ABOUT' />
      <TextWrapper>
        <Text>
          {t('ABOUT_1')}
          <br />
          {t('ABOUT_2')}
          <br />
          <br />
          {t('ABOUT_3')}
          <br />
          {t('ABOUT_4')}
          <br />
          <br />
          {t('ABOUT_5')}
          <br />
          {t('ABOUT_6')}
          <br />
          <br />
          {t('ABOUT_7')}
          <br />
          {t('ABOUT_8')}
          <br />
          <br />
          {t('ABOUT_9')}
          <br />
        </Text>
      </TextWrapper>
      <Video width='400' controls>
        <source src={MOVIE_FILE} type='video/mp4' />
        {t('YOUR_BROWSER_DOES_NOT_SUPPER_HTML_VIDEO')}
      </Video>
      <Text>{t('ABOUT_10')}</Text>
      <SubtitleText>{t('FIND_US_HERE')}</SubtitleText>
      <IconWithInfoTile
        icon='facebook'
        title='WlabadiDogsCare'
        subtitle='Facebook'
        fill={facebook}
        href={FACEBOOK_LINK}
      />
      <IconWithInfoTile
        icon='instagramColor'
        title='wlabadi_dogscare'
        subtitle='Instagram'
        fill='black'
        href={INSTAGRAM_LINK}
      />
      <SubtitleText>{t('DOWNLOAD')}</SubtitleText>
      <IconWithInfoTile
        icon='pdf'
        title='APPLICATION_FORM'
        subtitle='CLICK_TO_DOWNLOAD'
        href={PDF_FILE}
        download
      />
    </Wrapper>
  );
};

export default About;
