import styled from 'styled-components';

type tActive = {
  isActive: boolean;
};

export const Wrapper = styled.div`
  height: 95vh;
  width: 100vw;
  margin-bottom: 2rem;
`;

export const CarouselWrapper = styled.div`
  display: flex;
`;

export const PricingDetails = styled.div``;

export const Item = styled.div`
  display: flex;
  min-width: 100%;
  justify-content: space-evenly;
`;

export const Title = styled.h3`
  font-size: 2rem;
  font-family: 'Lobster';
  text-align: center;
  color: var(--theme-grey);
  margin: 1rem 0;
  padding: 0;
  max-width: 130px;
`;

export const Text = styled.p`
  margin: 5px;
  padding: 0;
  font-size: 1rem;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Breed = styled.li`
  margin: 0;
  justify-content: left;
`;

export const Photo = styled.img`
  object-fit: contain;
  margin: 1em;
  max-width: 35vw;
  height: 30vh;
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Service = styled.div`
  display: flex;
  margin: 0 2rem;
  justify-content: space-between;
`;

export const Info = styled.li``;

export const Price = styled.p`
  margin: 0;
  padding: 0;
`;

export const AdditionalText = styled.p`
  padding: 0;
  margin: 1rem 0 1rem 2rem;
  font-size: 1.1rem;
  color: var(--theme-dark-grey);
  font-weight: 700;
`;
