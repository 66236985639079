import icons from 'icons/icons';
import { mainGrey } from 'styles/colors';

export type tIcon = {
  icon: string;
  fill?: string;
  disable?: boolean;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
};

const Icon = ({
  icon,
  fill = mainGrey,
  disable = false,
  width = '1.5rem',
  height = '1.5rem',
  style,
  ...rest
}: tIcon): JSX.Element | null => {
  const IconComponent = icons[icon];

  if (!IconComponent) return null;
  return (
    <IconComponent
      style={{ minWidth: width, ...style }}
      width={width}
      height={height}
      fill={fill}
      {...rest}
    />
  );
};

export default Icon;
