import styled from 'styled-components';
import { Link } from 'react-scroll';
import menuWrapper from 'assets/images/menu-wrapper.png';

type tLink = {
  active: boolean;
};

export const NavDesktop = styled.nav`
  width: 100%;
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 100;
`;

export const NavLinksDesktop = styled(Link)`
  color: var(--theme-grey);
  text-decoration: none;
  box-sizing: border-box;
  font-size: 1.1rem;
  cursor: pointer;
  margin-right: 1rem;
`;

export const Nav = styled.nav`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 1rem 0 1rem 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: space-evenly;
`;

export const NavLinks = styled(Link)<tLink>`
  text-decoration: none;
  box-sizing: border-box;
  font-size: 0.75rem;
  cursor: pointer;
  min-width: 64px;
  color: var(--theme-grey);

  &.active {
    color: var(--theme-main-color);
  }
`;

export const NavBarName = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
`;

export const MainWrapper = styled.div`
  height: 54px;
  width: 54px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-main-color);
  position: fixed;
  bottom: 3.5rem;
`;

export const MenuImg = styled.div`
  width: 100%;
  height: 97px;
  position: fixed;
  bottom: 0rem;
  z-index: 100;
  background: url(${menuWrapper}) center;
  border: none;
`;
