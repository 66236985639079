import { ReactComponent as petsPaw } from './petsPaw.svg';
import { ReactComponent as photos } from './photos.svg';
import { ReactComponent as money } from './money.svg';
import { ReactComponent as phone } from './phone.svg';
import { ReactComponent as home } from './home.svg';
import { ReactComponent as location } from './location.svg';
import { ReactComponent as facebook } from './facebook.svg';
import { ReactComponent as instagram } from './instagram.svg';
import { ReactComponent as email } from './email.svg';
import { ReactComponent as polandFlag } from './polandFlag.svg';
import { ReactComponent as logo } from './logo.svg';
import { ReactComponent as upLine } from './upLine.svg';
import { ReactComponent as bottomLine } from './bottomLine.svg';
import { ReactComponent as UK } from './UK.svg';
import { ReactComponent as instagramColor } from './instagramColor.svg';
import { ReactComponent as pdf } from './pdf.svg';
import { ReactComponent as select } from './select.svg';
import { ReactComponent as arrow } from './arrow.svg';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  petsPaw,
  photos,
  money,
  phone,
  home,
  location,
  facebook,
  instagram,
  email,
  polandFlag,
  logo,
  upLine,
  bottomLine,
  UK,
  instagramColor,
  pdf,
  select,
  arrow,
};
