import PricingTabs from 'components/PricingTabs/PricingTabs';
import { useTranslation } from 'react-i18next';
import { ViewTitle } from 'styles/GlobalStyledComponents.style';
import { TitleWrapper, DogImg } from './TitleSection.style';

type tTitleSection = {
  imgSrc?: string;
  title: string;
  withButtons?: boolean;
  setIsWalks?: Function;
  walks?: boolean;
};

const TitleSection = ({
  imgSrc,
  title,
  withButtons = false,
  setIsWalks = () => {},
  walks = false,
}: tTitleSection): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <TitleWrapper>
        {imgSrc && <DogImg alt='Relaxed dog' src={imgSrc} />}
        <ViewTitle>{t(title)}</ViewTitle>
      </TitleWrapper>
      <PricingTabs withButtons={withButtons} setIsWalks={setIsWalks} walks={walks} />
    </>
  );
};

export default TitleSection;
