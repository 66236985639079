import styled from 'styled-components';

export const FlagWrapper = styled.button`
  min-height: 54px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: none;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FlagToChoose = styled(FlagWrapper)`
  position: absolute;
  top: 35px;
`;

export const SelectIconWrapper = styled.div`
  margin-left: 0.5rem;
`;
